import { FC, ReactNode } from 'react';
import { Footer, MobileNavMenu } from './footer';
import { Header } from './header';
import { ParallaxBackground } from 'ui/components/planet';
import Swapper, { QuickSwapButton } from './swapper';
import { InfoModals } from './info-modals';
import { useLocation } from 'react-router';
import { LaunchpadDesktopHeader } from 'ui/pages/selenium-launchpad/banners';

export const MainLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const location = useLocation();
  return (
    <div
      id="layout"
      className="flex h-dvh flex-col items-center justify-start overflow-y-auto overflow-x-hidden bg-background4 scrollbar-thin scrollbar-track-transparent scrollbar-thumb-white/20">
      {!location.pathname.startsWith('/launchpad') && <ParallaxBackground />}
      <Header />
      <LaunchpadDesktopHeader />
      {children}
      <Footer />
      <MobileNavMenu />
      <Swapper />
      <QuickSwapButton />
      <InfoModals />
    </div>
  );
};
