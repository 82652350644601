import { CardModal } from 'ui/components/modal';
import { createStore } from 'zustand-x';

export const infoModals = createStore('info-modals')(
  {
    termsOfUse: false,
    privacyPolicy: false,
  },
  {},
);

export const InfoModals = () => {
  const termsOfUse = infoModals.use.termsOfUse();
  const privacyPolicy = infoModals.use.privacyPolicy();
  return (
    <>
      <CardModal
        title="Terms of Use"
        open={termsOfUse}
        onClose={() => infoModals.set.termsOfUse(false)}
        contentClassName="p-4">
        <pre className="custom-scrollbar h-[700px] max-h-[80vh] w-[90vw] max-w-[600px] overflow-auto whitespace-pre-wrap rounded-xl bg-background1 p-4 text-start">
          {`We want your experience of Terraport to be safe and the best it can be and we always strive for continual improvement. At the heart of this is our commitment to maximise user safety. Terraport and the development team are audited and we have made the platform as safe as we can, however there are still risks which you need to be informed of.

All financial transactions carry risk whether in the traditional finance sector, or emerging 'crypto / web3' and 'decentralised finance' sectors. Terraport falls into the decentralised finance sector which is considered very high risk and experimental.

To ensure you are fully aware of the risks associated with Terraport and the wider 'crypto' sector please take some time to read the following sections.`}
          <br />

          <a
            href="https://terraport.gitbook.io/terraport-docs/safety-and-security/safety-and-risk-advice/regulatory-advice"
            target="_blank"
            className="p-2 text-white visited:text-white"
            rel="noreferrer">
            Regulatory Advice
          </a>
          <br />

          <a
            href="https://terraport.gitbook.io/terraport-docs/safety-and-security/safety-and-risk-advice/terraport-advanced-products"
            target="_blank"
            className="p-2 text-white visited:text-white"
            rel="noreferrer">
            Terraport Advanced Products
          </a>
          <br />

          <a
            href="https://terraport.gitbook.io/terraport-docs/safety-and-security/safety-and-risk-advice/specific-risks-of-cryptoassets"
            target="_blank"
            className="p-2 text-white visited:text-white"
            rel="noreferrer">
            Specific Risks of Crypto Assets
          </a>
          <br />

          {`If you do not understand the risks we encourage you to seek expert technical advice and financial advice from an independent financial advisor.

In all instances DO NOT invest funds you cannot afford to lose because in the event of their loss they are extremely likely not to be recoverable.

Thank you for reading these pages, have a safe financial journey through Terraport.`}
        </pre>
      </CardModal>
      <CardModal
        title="Privacy Policy"
        open={privacyPolicy}
        contentClassName="p-4"
        onClose={() => infoModals.set.privacyPolicy(false)}>
        <pre className="custom-scrollbar h-[700px] max-h-[80vh] w-[90vw] max-w-[600px] overflow-auto whitespace-pre-wrap rounded-xl bg-background1 p-4 text-start">
          {`This Privacy Policy describes how your personal information is collected, used, and shared when you visit or make a purchase from terraport.finance (the "Site").


Personal information we collect

When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device.

Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as "Device Information".

We collect Device Information using the following technologies:
- "Cookies" are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
- "Log files" track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
- "Web beacons", "tags", and "pixels" are electronic files used to record information about how you browse the Site.

Additionally when you contact us through the contact form(s) located at the Site, we collect certain information from you, including your name, email address, and phone number. We refer to this information as "Contact Information".

When we talk about "Personal Information" in this Privacy Policy, we are talking both about Device Information and Contact Information.


How do we use your personal information?

We use the Contact Information to:
- Communicate with you;
- When in line with the preferences you have shared with us, provide you with information or advertising relating to our products or services.

We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and advertising campaigns).




Sharing you personal Information

We share your Personal Information with third parties to help us use your Personal Information, as described above. We use Google Analytics to help us understand how our customers use the Site -- you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.

Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.


Behavioural advertising

As described above, we use your Personal Information to provide you with targeted advertisements or marketing communications we believe may be of interest to you.

You can opt out of targeted advertising by using the links below:
- Facebook: https://www.facebook.com/settings/?tab=ads
- Google: https://www.google.com/settings/ads/anonymous
- Bing: https://about.ads.microsoft.com/en-us/resources/policies/personalized-ads

Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance's opt-out portal at: http://optout.aboutads.info/.


Do not track

Please note that we do not alter our Site's data collection and use practices when we see a Do Not Track signal from your browser.


Your rights

If you are a European resident, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact form below.

Additionally, if you are a European resident we note that we are processing your information in order to contact you, or otherwise to pursue our legitimate business interests listed above. Additionally, please note that your information will be transferred outside of Europe, including to Canada and the United States.


Data retention

When you contact us through the Site, we will maintain your Contact Information for our records unless and until you ask us to delete this information.


Changes

We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.


Contact us

For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us at: info@terraport.finance

          `}
        </pre>
      </CardModal>
    </>
  );
};
