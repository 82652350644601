import clsx from 'clsx';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';

export const LaunchpadDesktopHeader = () => {
  const location = useLocation();
  return !location.pathname.startsWith('/launchpad') ? (
    <Link
      to="/launchpad"
      className={clsx(
        'sticky z-[3] flex w-full flex-col items-center justify-center bg-[url("/assets/selenium-launchpad/banner.svg")] bg-center bg-no-repeat text-sblue no-underline backdrop-blur-md',
        'top-24 hidden lg:flex',
      )}
      style={{
        background:
          'linear-gradient(94.51deg, rgba(0, 255, 228, 0.1) 0%, rgba(0, 25, 28, 0.5) 40%, rgba(0, 25, 28, 0.5) 60%, rgba(0, 255, 228, 0.1) 100%)',
      }}>
      <img src="/assets/selenium-launchpad/banner.svg" className="absolute inset-0 h-full w-full" />
      <div className="flex flex-row items-center gap-2 p-4">
        <img src="/assets/selenium-launchpad/logo-mobile.svg" className="size-7" />
        <span className="advent text-xl font-semibold">
          Selenium Launchpad is Live Now! Dont miss the chance to earn rewards!{' '}
          <span className="advent font-bold">Check it now!</span>
        </span>
      </div>
      <div className="h-px w-full bg-sblue" />
    </Link>
  ) : null;
};

export const LaunchpadMobileHeader = () => {
  const location = useLocation();
  return !location.pathname.startsWith('/launchpad') ? (
    <Link
      to="/launchpad"
      className={clsx(
        'sticky bottom-14 z-[3] flex w-full flex-col items-center justify-center bg-[url("/assets/selenium-launchpad/banner.svg")] bg-center bg-no-repeat text-sblue no-underline backdrop-blur-md lg:hidden',
      )}
      style={{
        background:
          'linear-gradient(94.51deg, rgba(0, 255, 228, 0.1) 0%, rgba(0, 25, 28, 0.7) 40%, rgba(0, 25, 28, 0.7) 60%, rgba(0, 255, 228, 0.1) 100%)',
      }}>
      <img src="/assets/selenium-launchpad/banner.svg" className="absolute inset-0 h-full w-full" />
      <div className="h-px w-full bg-sblue" />
      <div className="flex flex-row items-center gap-2 p-2">
        <img src="/assets/selenium-launchpad/logo-mobile.svg" className="size-7" />
        <span className="advent text-wrap text-xs font-semibold sm:text-lg">
          Selenium Launchpad is Live Now! Dont miss the chance to earn rewards!{' '}
          <span className="advent font-bold">Check it now!</span>
        </span>
      </div>
    </Link>
  ) : null;
};
