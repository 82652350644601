import { FC, Fragment } from "react";
import { useCountdown } from 'helper/countdown';
import './style.css';
import leftstars from './left-stars.svg';
import rightstars from './right-stars.svg';
import meteorterra from './MeteorTerra.png'
import spaceship from './SpaceShip.png';


export const CompetitionPage: FC = () => {
  const date = new Date('2024-10-31T00:00:00.711Z');
  const cd = useCountdown(date);
  return (
    <Fragment>
      <section className="competition">
        <div className="top-text">Meme Token</div>
        <div className="title">Competition!</div>
        <div className="description">
          We're excited to announce our Meme Token Competition with a grand prize of $5,000.00!
        </div>
      </section>

      <section className="cosmic-prize">
        <div className="content">
          <div className="small-title">COSMIC PRIZE</div>
          <div className="prize">$ 5,000.00 *</div>
          <div className="description">*The prize will be provided in $TERRA as the winner Token Pool Liquidity</div>
        </div>
        <a className="button-cta" href="https://docs.google.com/forms/d/e/1FAIpQLSf-MXOrb7fJeFP1wLQgIZS4iw7ADgp2w34wgOhJ3tPo40Revg/viewform" target="_blank" rel="noopener noreferrer">
          <div className="small">Registration ends: {cd[0]}d {cd[1]}h {cd[2]}m</div>
          <div className="big">Register Now!</div>
        </a>

        <img className="left-stars" src={leftstars} />
        <img className="right-stars" src={rightstars} />
        <img className="metero-terra" src={meteorterra} />
        <img className="space-ship" src={spaceship} />
      </section>

      <section className="rules">
        <div className="title">Participation rules</div>
        <ul>
          <li>Register on <a href="https://docs.google.com/forms/d/e/1FAIpQLSf-MXOrb7fJeFP1wLQgIZS4iw7ADgp2w34wgOhJ3tPo40Revg/viewform" target="_blank" rel="noopener noreferrer">this form</a>.</li>
          <li>The token must be owned by you.</li>
          <li>The tokens must be “Meme Tokens”.</li>
          <li>The “Meme Token” must be whitelisted on Terraport. </li>
          <li>The registration will be opened untill 31st October 2024.</li>
        </ul>

        <div className="title">What you need to know</div>
        <ul>
          <li>The competition will start on 1st November and will end on the 30th November.</li>
          <li>The winner will be the one who generates the most volume in 30 days.</li>
          <li>The $ 5,000.00 prize will be placed as liquidity in the pool with the winning token as $TERRA. If it does not exist it will be created.</li>
          <li>The token owner must have at least $ 5,000.00 in tokens that must be used for the pair if they are the winner.</li>
          <li>The prize will be delivered in one week.</li>
        </ul>
      </section>
    </Fragment>
  );

}

export default CompetitionPage;
